import React, { ChangeEvent, FocusEventHandler, useCallback } from "react";
import { Input } from "reactstrap";

const EMPTY = "";

interface Item {
  id: string;
  name: string;
}

export interface SelectInputProps {
  id?: string;
  placeholder: string;
  items: Item[];
  selected?: Item;
  invalid: boolean;
  disabled: boolean;
  onSelect: (item: Item) => void;
  onBlur?: FocusEventHandler;
  item2id?: (item: Item) => string;
  item2name?: (item: Item) => string;
}

export default function SelectInput({
  id,
  placeholder,
  items,
  selected,
  invalid,
  disabled,
  onSelect,
  onBlur,
  item2id = (i) => i.id,
  item2name = (i) => i.name,
}: SelectInputProps) {
  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const id = e.target.value;
      const item = items.find((i) => item2id(i) === id);

      if (item) {
        onSelect(item);
      }
    },
    [items, onSelect]
  );

  if (!items) {
    return null;
  }
  return (
    <Input
      id={id}
      type="select"
      invalid={invalid}
      disabled={disabled}
      value={selected ? item2id(selected) : EMPTY}
      onChange={handleChange}
      onBlur={onBlur}
    >
      <option key={-1} value={EMPTY} disabled={true}>
        {placeholder}
      </option>

      {items.map((item) => (
        <option
          key={item2id(item)}
          value={item2id(item)}
          onClick={() => onSelect(item)}
        >
          {item2name(item)}
        </option>
      ))}
    </Input>
  );
}
